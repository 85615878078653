.po-details-page {
  width: 100%; 
  /* position: absolute; */
}

.purchase-order-detail {
  padding:0px 58px 28px 58px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  background-color: rgb(69, 181, 237,0.1);
  border-radius: 8px;
  padding: 20px;
  border:1px dashed rgb(69, 181, 237)
}

.grid-item {
  padding: 0px 10px;
  font-size: small;
}

.grid-item h1 {
  margin-bottom: 10px;
}

.grid-item h2 {
  margin-bottom: 5px;
  font-size: small;
}

.grid-item p {
  margin: 0;
}
