.shipment-main {
  width: 100vw;
  height: 100%;
  background-color: #f2f5f9;
}
.map-container {
  width: 100vw;
  position: relative;
}
.pointer1 {
  position: absolute;
  top: 47%;
  left: 22%;
  display: flex;
  flex-direction: column;
}
.pointer2 {
  position: absolute;
  top: 56%;
  left: 39%;
  display: flex;
  flex-direction: column;
}
.pointer3 {
  position: absolute;
  top: 38%;
  left: 55%;
  display: flex;
  flex-direction: column;
}
.pointer4 {
  position: absolute;
  top: 82%;
  left: 60%;
  display: flex;
  flex-direction: column;
}
.pointer5 {
  position: absolute;
  top: 58%;
  left: 71%;
  display: flex;
  flex-direction: column;
}
.map {
  width: 100vw;
}
.list-container {
  padding-top: 13px;
  padding-left: 13px;
  padding-right: 13px;
}
.header-details {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.header-name1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 10px;
}
.header-name2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: -3px;
  color: #000000;
}
.list-count {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13.5987px;
  line-height: 11px;
  color: #000000;
  width: 41px;
  height: 22px;
  background-color: #dbdbdb;
  border-radius: 11px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.searchbar-div {
  position: relative;
  display: flex;
  align-items: center;
}
.search-icon {
  position: absolute;
  left: 14px;
}
.search-bar {
  padding-left: 40px;
  width: 326px;
  height: 32px;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
.action-form {
  display: flex;
  gap: 8px;
}
.filter-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  color: #787878;
  font-weight: 400;
  font-size: 11px;
  width: 76px;
  height: 34px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.e-hide {
  visibility: hidden;
}
.e-grid {
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.e-columnheader {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #798792;
  border-bottom: 1px solid #e5e7eb;
}
.e-table {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  padding-bottom: 3px;
}
.e-row {
  height: 65px;
}
.e-rowcell {
  border-bottom: 1px solid #e5e7eb;
}
table {
  border-bottom: 0px;
}
.e-row :nth-child(1) {
  color: #0082fb;
}
.e-gridheader {
  border-bottom: 1px solid #e5e7eb;
}
.grid {
  height: 70vh;
  /* margin-top: 21px; */
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
}
.k-grid {
  height: 80vh;
  border-radius: 20px !important;
  font-size: 10px !important;
  /* border: none !important; */
  border-style: none !important;
}
.k-grid-md td{
  font-size: 12px !important;
}
.k-table-tbody {
  font-size: 12px;
  font-weight: 400 !important;
  color: black;
  line-height: 18px;
}
.k-grid-header {
  font-size: 12px;
  font-weight: 500 !important;
  color: #798792 !important;
  line-height: 18px;
  /* border-radius: 20px !important; */
}
.k-grid-header-wrap .k-header {
  border-right: none !important;
  /* border-radius: 20px !important; */
}
.grid .k-grid td {
  white-space: nowrap;
  padding: 10px 15px !important;
}
