.dashboard-main {
  padding: 20px 13px;
}
.dashboard-header {
  display: flex;
  justify-content: space-between;
}
.dashboard-greeting {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.dashboard-actions {
  display: flex;
  height: 100%;
  justify-content: center;
}
.report-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: black;
  padding: 5px;
  align-items: center;
  gap: 7px;
}
.report-label {
  color: #828282;
}
.action-print {
  width: 28px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.action-filter {
  width: 76px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2ba3f3;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  gap: 7px;
  text-align: center;
  color: white;
  font-weight: 500;
  &:disabled {
    opacity: 0.5;
  }
}
.action-buttons {
  display: flex;
  gap: 5px;
}
.dashboard-details-screen {
  display: flex;
  height: 82vh;
  gap: 11px;
  background-color: inherit;
  padding-top: 18px;
}
.po-counts {
  width: 25%;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 27px 28px;
}
.po-details {
  width: 30%;
  background-color: #ffffff;
  border-radius: 20px;
}
.po-details-list::-webkit-scrollbar{
  display:none;
}
.po-details-list{
  height: 92%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.po-detail-list-item .pos {
  color: #2d8dff;
}
.dashboard-statitics {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.po-counts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14.4395px;
  line-height: 22px;
  color: #000000;
}
.moreIcon {
  height: fit-content;
  display: flex;
  align-items: center;
}
.po-total-count {
  margin-top: 4px;
  font-weight: 500;
  width: fit-content;
  font-size: 36.0987px;
  line-height: 29px;
  color: #2d8dff;
}
.po-total-count:hover,
.po-detail-count .count-div:hover > div,
.statitics-job-count .second-div:hover,
.chart-count:hover {
  color: blue;
  cursor: pointer;
  text-shadow: 1px 2px 4px rgba(98, 198, 231, 0.38);
  text-decoration: underline;
}
.chart-count:hover {
  font-weight: 700;
  font-size: large !important;
  transition: ease 500ms !important;
  text-decoration: underline;
}
.po-detail-count .count-div:hover > div {
  font-weight: 500;
  transition: all 500ms;
}
.po-detail-count {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto auto;
  row-gap: 16px;
  margin-top: 27px;
  font-size: 12px;
}
.detail-count-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2d8dff;
}
.po-count-img {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.po-details :first-child {
  display: flex;
  gap: 4px;
}
.po-details {
  padding: 28px 35px 27px 0px;
}
.po-detail-list-head {
  font-weight: 500;
  font-size: 14.4395px;
  line-height: 22px;
  color: #1e74f5;
  margin-bottom: 21px;
  padding-left: 26px;
  display: flex;
  justify-content: space-between;
}
.po-detail-list-head span {
  margin-left: 4px;
  width: 47px;
  height: 22px;
  background: #eaeaea;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14.4395px;
  line-height: 22px;
  color: #666666;
}
.po-detail-list-item {
  display: flex;
  height: 46px;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  padding-left: 25px;
  margin-bottom: 9px;
}
.po-detail-list-item:hover {
  box-shadow: inset 10px 0px 10px -8px rgb(121, 220, 247);
  transition: all 700ms;
  font-weight: 600;
  color: #0367fe;
  cursor: pointer;
  text-decoration: underline;
}
.po-detail-list-item hr {
  rotate: 90deg;
  height: 220px;
  background-color: #eaeaea;
  border: none;
  width: 2px;
}
.statitics-job-count {
  display: flex;
  height: 20%;
  border-radius: 20px;
  background-color: white;
}
.statitics-job-count .first-div {
  background: linear-gradient(90.86deg, #29bcfb -2.74%, #cfff69 114.56%);
  box-shadow: 0px 10px 30px rgba(134, 134, 134, 0.1);
  width: 46%;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 42px;
}
.statitics-job-count .first-div .first-text {
  font-weight: 600;
  font-size: 23.5147px;
  color: #000000;
}
.statitics-job-count .first-div .second-text {
  font-weight: 400;
  font-size: 9.01854px;
  color: #000000;
  margin-top: -6px;
}
.statitics-job-count .second-div {
  font-weight: 600;
  font-size: 36.1px;
  color: #1e74f5;
  display: flex;
  align-items: center;
  padding-left: 38px;
}
.statitics-mode {
  display: flex;
  gap: 12px;
  height: 20%;
}
.mode-statitics-div {
  width: 20%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 30px rgba(134, 134, 134, 0.1);
}
.mode-statitics-div:hover {
  cursor: pointer;
  background: rgb(244, 247, 246);
}
.mode-statitics-div:hover .count {
  color: #0550ab;
  text-shadow: 1px 2px 4px rgba(98, 198, 231, 0.38);
  text-decoration: underline;
}
.mode-statitics-div .label {
  font-weight: 600;
  font-size: 13px;
  color: #000000;
}
.mode-statitics-div .count {
  font-weight: 600;
  font-size: 20px;
  color: #2d8dff;
}
.statitics-graph {
  height: 60%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.statitics-graph {
  padding: 30px 31px;
}
.statitics-graph .header .header-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #1e74f5;
}
.dashboard-chart {
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
.k-chart {
  height: 90% !important;
}
.k-label {
  visibility: hidden !important;
}
.k-floating-label-container {
  padding: 0px !important;
  height: 100%;
  width: 100% !important;
}
.date-range-picker .k-input-solid {
  background-color: white;
  border-radius: 3px !important;
  border: 1px solid #dcdcdc !important;
  height: 30px;
  width: 300px;
  font-size: x-small !important;
  font-weight: 500 !important;
  font-family: "poppins" !important;
}
.date-range-picker .k-dateinput {
  width: fit-content !important;
}
.date-range-picker {
  height: fit-content;
  /* width: 300px; */
}
