.docUpload-details-page {
  margin: 0px 15vw;
  max-width: 95vw;
  height: 100%;
}
.docUpload-background {
  max-width: 95vw;
  height: 100%;
  position: relative;
}
.docUpload-image {
  max-width: 95vw;
  width: 70.2vw;
  max-height: 270px;
  object-fit: cover;
}
.docUpload-content {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 24px 32px;
}
.enginRoom-detail-1 {
  font-weight: 600;
  font-size: 24.3368px;
  line-height: 37px;
  color: #ffffff;
  margin-bottom: 20px;
}
.enginRoom-detail-2 {
  display: flex;
  color: #ffffff;
}
.detail-label {
  display: flex;
  width: 70px;
}
.docUplaod-details {
  padding: 15px 58px;
  max-width: 96vw;
  height: max-content;
  overflow: hidden;
  height: 100%;
  background-color: #ffffff;
}
.docUpload-detail-main {
  width: 100%;
  padding: 5px 5px;
}
.docUpload-detail-main .header {
  font-size: 18px;
  font-weight: 500;
  color: black;
}
.docUpload-detail-main .form {
  /* --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity)); */
  /* height: 100%; */
}

.docUpload-detail-main .form .docmain {
  margin-top: 10px;
  /* display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)); */
  gap: 16px 28px;
  display: flex;
}
.docUpload-detail-main .form .docmain .doc-uploader {
  margin: 15px 0px;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
  border: 2px;
  border-color: rgb(156 163 175);
  border-style: dotted;
}
.docUpload-detail-main .form .docmain .doc-uploader input {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  opacity: 0;
}
.docUpload-detail-main .form .docmain .doc-uploader img {
  height: 40px;
  width: auto;
  margin-bottom: 16px;
}
.docUpload-detail-main .form .docmain .doc-uploader h6 {
  font-size: 14px;
  font-weight: 500;
  color: rgb(82 82 91);
  margin-bottom: 2px;
}
.docUpload-detail-main .form .docmain .doc-uploader p {
  font-size: 14px;
  font-weight: normal;
  color: rgb(156 163 175);
}
.docUpload-detail-main .form .docmain .doc-Show-Container {
  margin-top: 2px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  width: 70%;
  overflow-x: auto;
  vertical-align: middle;
  /* inline-block min-w-full py-2 align-middle md:px-6 lg:pr-8 */
  /* -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 */
}
.docUpload-detail-main .form .docmain .doc-Show-Container .doc-show {
  overflow: hidden;
  margin-top: 12px;
  height: 100%;
  position: relative;
}
.docUpload-detail-main .form .docmain .doc-Show-Container .doc-show table {
  min-width: 100%;
}
.docUpload-detail-main .form .docmain .doc-Show-Container .doc-show table tr {
  border-bottom: 2px dashed black !important;
}
.docUpload-detail-main .pdfimg {
  width: 48px;
  white-space: nowrap;
  padding: 0px 12px;
  padding-right: 2px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(17 24 39);
}
.docUpload-detail-main .pdfimg img {
  height: 24px;
  width: auto;
  margin-bottom: 16px;
}
.docUpload-detail-main .pdfDetails {
  white-space: nowrap;
  padding: 1px 12px;
  padding-right: 2px;
  font-size: small;
  font-weight: 500;
  color: rgb(17 24 39);
}
.docUpload-detail-main .pdfSize {
  font-size: small;
  font-weight: 400;
  color: rgb(107 114 128);
}
.docUpload-detail-main .download-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 4px 8px 12px;
  text-align: right;
  font-size: small;
  font-weight: 500;
}
.docUpload-detail-main .download-div a {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  border: 1px;
  border-color: transparent;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  /* color: white; */
}
.docUpload-detail-main .download-div svg {
  height: 24px;
  width: 24px;
}
.docUpload-detail-main .download-div button {
  margin-left: 16px;
  display: inline-flex;
  align-items: center;
  border: 1px;
  border-color: transparent;
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  border-radius: 4px;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.docUpload-detail-main .download-div button svg {
  height: 24px;
  width: 24px;
}
.docUpload-detail-main .save {
  margin-top: 28px;
  margin-left: auto;
}
.docUpload-detail-main .save button {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding: 8px 16px;
  border: 1px;
  border-color: rgb(77 124 15);
  background-color: rgb(77 124 15);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  color: white;
}
.engineRoom-empty-documents {
  margin-top: 10px;
  padding-left: 30px;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #6b7280;
}
