.document-table {
  min-width: 100%;
}
.document-table-row {
  border: 2px solid black;
  border-bottom-width: 1px;
  border-style: dashed;
  border-color: rgb(156 163 175);
}
.document-table-row .icon-td {
  width: 3rem;
}
.document-table-row .filename-td,
.document-table-row .icon-td {
  white-space: nowrap;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  padding-right: 0.25rem /* 4px */;
  font-size: 14px;
  font-weight: 500;
  color: rgb(17 24 39);
}
.document-table-row .filename-td p {
  color: rgb(107 114 128);
  font-weight: 400;
  font-size: 14px;
}
.document-table-row .documentType {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  white-space: nowrap;
}
.document-table-row .download-td {
  /* display: flex; */
  position: relative;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.25rem;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
}
.document-table-row .download-link {
  /* display: inline-flex;
  width: 50px;
  height: 50px;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 2px;
  border-color: gray;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05); */
  display: inline-block;
  border: 1px solid #ced0d1;
  border-radius: 4px;
  background-color: #daebf4;
  color: rgb(37 99 235);
  font-size: 16px;
  text-decoration: none;
  box-shadow: inset;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.loading-icon {
  animation: spin 1s linear infinite;
  color: rgb(37 99 235);
  margin-left: 5px;
}
.empty-documents {
  margin-top: 10px;
  padding-left: 30px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #6b7280;
}
.tdocumentbody {
  position: relative;
  height: 10px;
}
.empty-documentType {
  display: flex;
  align-items: center;
  font-size: 10px;
  gap: 5px;
  font-weight: 500;
}
