.login-main {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #3b8fff;
}
.login-profile {
  width: 40vw;
  background-color: #3b8fff;
  position: relative;
}
.login-form {
  width: 60vw;
  background: #ffffff;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  /* border-radius: 20px 0px 0px 20px; */
}
.login-img {
  position: absolute;
  top: 20%;
  left: 42.5%;
  z-index: 10;
}
.imgFlip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  bottom: 0;
  right: -42px;
}
.desc-div {
  position: absolute;
  bottom: 5%;
  left: 30%;
}
.img-normal {
  position: absolute;
  top: -25px;
  left: -40px;
}
.profile-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  width: 263px;
  height: 48px;
  color: #ffffff;
}
.voyagelogo {
  position: absolute;
  top: 21px;
  right: 38px;
  width: 130px;
}
.login-form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-header {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
}
.login-form-elements {
  margin-top: 52px;
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login-form-elements input[type="text"],
.password-box input,
.login-form-elements select {
  width: 100%;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #ffffff;
  border-bottom: 1px solid #c5c5c5;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: black;
}
.login-form-elements input[type="text"]::placeholder,
.password-box input::placeholder {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #adadad;
}
.login-form-elements input[type="text"]:focus,
.password-box input:focus,
.login-form-elements select:focus {
  outline: none;
  border: none;
  background-color: #ffffff;
  border-bottom: 1px solid #3b8fff;
}
.show-hide-button {
  border: none;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 10px;
  background-color: inherit;
}
.show-hide-button .map-hide-logo {
  height: 12px;
}
.password-box {
  position: relative;
}
.password-marker {
  position: absolute;
  width: 19px;
  height: 2px;
  border: 0px;
  background: #bcbcbc;
  rotate: 45deg;
  left: 7px;
  top: -1px;
}
.login-forgot {
  width: 100%;
  text-align: end;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #3b8fff;
}
.login-button {
  margin-top: 44px;
  height: 45px;
  width: 100%;
  text-align: center;
  border: #3b8fff;
  background-color: #3b8fff;
  border-radius: 7px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.login-error {
  margin-top: 20px;
  font-weight: 500;
  font-size: 12px;
  animation: bounce 1s infinite;
  color: red;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.login-loader {
  animation: spin 1s linear infinite;
  height: 10px;
  width: 10px;
  margin-right: 12px;
}
.loading-icon {
  height: 20px;
  width: 20px;
  margin-right: 12px;
}
