.shipment-details-page {
  margin: 0px 15vw;
  max-width: 95vw;
  /* width: 100%;  */
  position: absolute;
}
.voyage-background {
  max-width: 80vw;
  height: 45vh;
  position: relative;
}
.banner-image {
  max-width: 95vw;
  width: 65.7vw;
  max-height: 270px;
  object-fit: cover;
  background: linear-gradient(90deg, #000000 0%, #0d6597 100%);
}
.banner-content {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 24px 32px;
  width: 60%;
}
.shipment-banner-details {
  display: flex;
  align-items: center;
}
.detail-1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;
  color: #ffffff;
}
.detail-divider {
  width: 19px;
  height: 1.5px;
  background-color: #ffffff;
  transform: rotate(90deg);
  margin-left: 10px;
}
.detail-desc {
  font-weight: 400;
  font-size: 21.0919px;
  line-height: 32px;
  color: #ffffff;
}
.detail-2 {
  font-weight: 400;
  font-size: 14.6021px;
  line-height: 22px;
  color: #ffffff;
}
.locationbar-container {
  display: flex;
  font-weight: 600;
  font-size: 14.6021px;
  line-height: 22px;
  color: #ffffff;
  gap: 5px;
  margin-top: 26px;
}
.sub-details {
  display: flex;
  margin-top: 30px;
  align-items: center;
  width: 100%;
}
.sub-detail-name {
  font-weight: 500;
  font-size: 14.6021px;
  line-height: 22px;
  color: #ffffff;
}
.sub-detail-desc {
  font-weight: 600;
  font-size: 14.6021px;
  line-height: 22px;
  color: #ffffff;
}
.sub-divider {
  width: 63.28px;
  margin-left: -1px;
  height: 2px;
  position: absolute;
  background-color: rgba(0, 143, 220, 0.3);
  border: 0px;
  /* border: 2.43368px solid rgba(0, 143, 220, 0.3); */
  transform: rotate(90deg);
  right: 10px;
  top: 15px;
}
.sub-details-1 {
  width: 33.33%;
  position: relative;
}
.tabs {
  display: flex;
  gap: 220px;
  height: 30px;
  padding: 0px 58px;
}
.tabs-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.label-1 {
  color: #419df1;
}
.dashboard-details {
  padding: 29px 0px;
  max-width: 65vw;
  height: 50vh;
  margin-bottom: 50px;
  background-color: #ffffff;
}
.react-tabs__tab {
  font-weight: 500;
  line-height: 26px;
  background-color: inherit !important;
  color: #525252;
  border: none !important;
  padding: 0px !important;
}
.react-tabs__tab--selected {
  color: #419df1 !important;
  /* border-bottom: 2px solid #419DF1!important; */
}
.react-tabs__tab--selected::before,
.react-tabs__tab--selected:focus::after {
  content: "" !important;
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background-color: #419df1 !important;
  border-radius: 38.9388px;
}
.react-tabs__tab-list {
  border: none !important;
  height: 30px !important;
  display: flex;
  gap: 20px;
}
.react-tabs {
  height: 100%;
}
.react-tabs__tab-panel--selected {
  height: 100%;
}
