@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body,
#root,
.App {
  height: 100%;
  font-family: "Poppins" !important;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #71bce1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f3696;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.textloader-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.loader {
  height: 4px;
  background-color: rgb(209 213 219);
  border-radius: 9999px;
  width: 100%;
}
.list-loader-container {
  max-width: 28rem;
  padding: 10px 30px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  border-radius: 2px;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.loader-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.list-item-1 {
  height: 8px;
  background-color: rgb(209 213 219);
  border-radius: 9999px;
  width: 150px;
}
.list-item-2 {
  height: 1px;
  background-color: rgb(209 213 219);
  border-radius: 9999px;
  width: 56px;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.status-container {
  /* max-width: 100%; */
  height: 38vh;
  padding: 5px;
  border-radius: 4px;
  animation: pulse 2s infinite;
  border: 1px solid #ccc; /* Replace dark:border-gray-700 with a specific color or remove it */
}

.status-container > .inner-container {
  display: flex;
  border: 1px solid #eee; /* Replace border-gray-100 with a specific color or remove it */
  height: 100%;
  gap: 8px;
  align-items: flex-end;
}

.status-container > .inner-container > div {
  width: 100%;
  height: 100%;
  background-color: #ccc; /* Replace bg-gray-300 with a specific color or remove it */
  border-radius: 8px;
}

/* Adjust the heights of each div according to your requirements */
.status-container > .inner-container > div:nth-child(1) {
  height: 80%;
}

.status-container > .inner-container > div:nth-child(2) {
  height: 50%;
}

.status-container > .inner-container > div:nth-child(3) {
  height: 60%;
}

.status-container > .inner-container > div:nth-child(4) {
  height: 70%;
}

.status-container > .inner-container > div:nth-child(5) {
  height: 50%;
}

.status-container > .inner-container > div:nth-child(6) {
  height: 75%;
}

.status-container > .inner-container > div:nth-child(7) {
  height: 60%;
}

.status-container > .inner-container > div:nth-child(8) {
  height: 60%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
