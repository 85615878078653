.shipment-poes-main {
  margin-top: 1.75rem;
  height: 100%;
  padding: 0px 58px;
}
.shipment-poes-sub {
  height: 41vh;
  padding-bottom: 10px;
  overflow-x: hidden;
  min-height: 100px;
  position: relative;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  @media (min-width: 640px) {
    .sm\:rounded-lg {
      border-radius: 0.5rem /* 8px */;
    }
  }
}
.shipment-poes-sub table {
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: rgb(107 114 128 / 1);
  border: 1px;
  border-radius: 8px;
  overflow-x: hidden;
}
.shipment-poes-sub table thead {
  font-size: 14px;
  color: rgb(55 65 81 / 1);
  text-transform: uppercase;
  background-color: rgb(229 231 235 / 1);
  border-radius: 8px 8px 0px 0px;
  position: sticky;
  top: 0;
}
.shipment-poes-sub th {
  padding: 12px 24px;
}
.shipment-poes-sub tbody tr {
  border-bottom-width: 1px;
  background-color: white;
}
.shipment-poes-sub tbody td {
  border-left-width: 1px;
  padding: 16px 24px;
}
.shipment-poes-sub tbody .poNo {
  color: rgb(17 24 39 / 1);
  white-space: nowrap;
  font-weight: 500;
}
