.count-container {
  display: flex;
  gap: 9px;
  margin-left: 20px;
}
.count-box {
  width: auto;
  height: 51px;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #acd7ff;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  justify-content: center;
}
.count-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.count-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #858585;
}
.box-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.count {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.po-search-bar {
  padding-left: 40px;
  width: 170px;
  height: 32px;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}
.po-header-actions {
  display: flex;
  /* align-items: flex-end; */
  /* grid-template-columns: auto auto auto; */
  gap: 5px;
}
.po-action-btn-container {
  grid-column: 3 span;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.excelExport {
  display: flex;
  height: 35px;
  width: fit-content;
  padding: 0px 10px;
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  border: 1px solid rgb(212 212 216);
  border-radius: 4px;
}
.search-input {
  display: flex;
  height: 30px;
  width: 200px;
  background-color: white;
  text-align: start;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 30px;
  gap: 6px;
  border: 1px solid rgb(212 212 216);
  border-radius: 2px;
}
.search-icon {
  position: absolute;
  height: 15px;
  width: 15px;
  top: 50%;
  left: 7%;
  transform: translate(-50%, -50%);
}
.search-div {
  position: relative;
  height: fit-content;
}
.po-action-button {
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  padding: 0px 8px;
  align-items: center;
  height: 34px;
  border: 1px solid rgb(77 124 15);
  background-color: rgb(77 124 15);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  color: white;
  &:hover {
    background-color: rgb(54 83 20);
    border-color: rgb(54 83 20);
    outline: none;
  }
  &:disabled {
    background-color: slategray;
    color: slategrey;
    border-color: slategrey;
    box-shadow: none;
  }
}
.po-location-input {
  display: flex;
  height: 30px;
  width: 200px;
  background-color: white;
  text-align: start;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 22px;
  gap: 4px;
  border: 1px solid rgb(212 212 216);
  border-radius: 4px;
  /* &:focus,
  &:focus-within {
    border-width: 2px ;
    border-color: rgb(59, 130, 246) ;
    box-shadow: none ;
    outline: none;
  } */
}
.po-detail-table {
  min-width: 100%;
  border-top-width: calc(1px * calc(1));
  border-bottom-width: calc(1px);
  border-color: rgb(209 213 219);
}
.po-detail-table thead {
  background-color: rgb(249 250 251);
}
.po-detail-table thead th {
  white-space: nowrap;
  padding: 0px 14px;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: rgb(17 24 39);
}
.po-detail-table tbody {
  border-top-width: calc(1px * calc(1));
  border-bottom-width: calc(1px);
  border-color: rgb(229 231 235);
  background-color: white;
}
.po-detail-table tbody td {
  white-space: nowrap;
  padding: 4px;
  font-size: 12px;
  color: rgb(17 24 39);
}
.k-detail-cell {
  padding: 0px !important;
  background: white !important;
}
.k-detail-cell td,
.k-detail-cell th {
  padding: 15px 10px !important;
}
.po-dashboard-actions {
  display: flex;
  height: 100%;
  margin-top: 5px;
  justify-content: end;
}
.po-grid {
  position: relative;
}

.MultCompo .k-chip-md {
  font-size: 9px !important;
  font-weight: 500 !important;
}

.MultCompo .k-input-values {
  overflow-y: auto;
}
