.shipment-detail-main {
  padding-bottom: 48px;
  height: 100%;
  background-color: white;
}
.top-div {
  display: flex;
  position: relative;
  gap: 42px;
  padding: 36px 58px;
}
.shipment-details {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.events {
  width: 55%;
  border-left: 2.36583px solid #eaeaea;
  padding-left: 42px;
  margin-left: 42px;
}
.div-header {
  font-weight: 600;
  font-size: 21.9031px;
  text-align: start;
  width: fit-content;
  line-height: 33px;
  margin-bottom: 13px;
  color: #000000;
}
.div-content {
  margin-top: 40px;
}
.details-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
}
.details-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 2.43368px solid #eaeaea;
}
.shipment-label {
  font-weight: 500;
  font-size: 14.6021px;
  line-height: 22px;
  color: #555555;
}
.label-property {
  font-weight: 500;
  font-size: 14.6021px;
  line-height: 22px;
  color: #a9a9a9;
}
.timeline {
  display: flex;
  margin-top: 30px;
  margin-left: 16px;
  height: 20px;
  width: 100%;
}
.timeline-container {
  width: 100%;
}
.timeline-tick {
  position: absolute;
  left: -12px;
  margin-right: 12px;
  margin-top: 15px;
}
.timeline-Icon {
  position: absolute;
  left: -20px;
  margin-right: 12px;
  margin-top: 6px;
}
.timeline-header {
  margin-top: -7px;
}
.timeline-header2 {
  margin-top: 60px;
}
.timeline-line {
  display: flex;
  margin-left: 12px;
  margin-top: 16px;
  position: relative;
  border-left: 1px solid #d0d0d0;
  overflow-x: visible;
}
.sea-header {
  font-weight: 600;
  font-size: 14.6021px;
  line-height: 22px;
  color: #5884f8;
}
.timeline-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
}
.timeline-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.descript-1 {
  font-weight: 500;
  font-size: 14.6021px;
  line-height: 22px;
  color: #000000;
}
.descript-2 {
  font-weight: 400;
  font-size: 12.1684px;
  line-height: 18px;
  color: #858585;
}
.-mt-1 {
  margin-top: -15px;
}
.relative {
  position: relative;
  margin-bottom: 36px;
}
.container-count {
  position: absolute;
  width: 31.64px;
  height: 31.64px;
  left: 115px;
  top: -15px;
  background: #6fa300;
  border-radius: 100%;
  font-weight: 600;
  font-size: 14.6021px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-grid {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  gap: 20px;
}
.container {
  padding: 0px 20px;
  height: 187.39px;
  background: #f3f3f3;
  border-radius: 30.421px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.job-details {
  font-size: 12px;
  font-weight: 500;
}
.job-type,
.job-no {
  display: flex;
  width: 100%;
}
.job-type .label,
.job-no .label {
  min-width: 60px;
}
.job-type .value,
.job-no .value {
  text-align: end;
  width: 100%;
}
.dimension {
  font-weight: 600;
  font-size: 20.3929px;
  line-height: 43px;
  color: #000000;
}
.total-count {
  font-weight: 500;
  font-size: 13.3852px;
  line-height: 20px;
  color: #575757;
}
.package-div {
  padding: 0px 58px;
  margin-bottom: 30px;
}
