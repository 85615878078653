.main {
  width: 100vw;
  height: 100vh;
}
.navbar {
  border-top: 4px solid #207ad3;
  /* border-color: #207ad3; */
  height: 49px;
  width: 100%;
  /* border: 1px; */
  background-color: white;
  box-shadow: 0px 4px 4px rgba(222, 222, 222, 0.2);
  display: flex;
  justify-content: space-between;
}
.bars {
  height: 20px;
  width: 24px;
  color: #747474;
  padding-left: 28px;
}
.navbar-content1 {
  display: flex;
  align-items: center;
  height: 100%;
}
.line {
  position: absolute;
  left: 71px;
  rotate: 90deg;
  width: 30px;
  height: 1px;
  border: 0px;
  background-color: #dbdbdb;
}
.logoShip {
  padding-left: 42px;
  height: 95%;
}
.logoName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100px;
  padding-left: 11px;
}
.logoText1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: start;
  width: 100%;
  line-height: 30px;
  color: #207ad3;
}
.logoText2 {
  margin-top: -5px;
  font-style: normal;
  width: 100%;
  font-weight: 400;
  text-align: start;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
}
.tablist {
  display: flex;
  height: 100%;
  padding-left: 70px;
}
.defaultTab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  height: 100%;
  line-height: 18px;
  padding: 0px 4px;
}
a:-webkit-any-link {
  text-decoration: none;
}
.map-hider {
  border: 1px solid #cfcfcf;
  border-radius: 15.5px;
  width: 125px;
  height: 31px;
  margin-right: 121px;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #3e3e3e;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 12px;
}
.navbar-content2 {
  display: flex;
  align-items: center;
  padding-right: 28px;
}
.map-hide-logo {
  width: 20px;
  height: 15px;
}
.eye-marker {
  position: absolute;
  width: 19px;
  height: 2px;
  border: 0px;
  background: #3e3e3e;
  rotate: 45deg;
  left: 19px;
}
.bell-circle {
  border-radius: 100%;
  position: relative;
  height: 30px;
  width: 30px;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 121px;
  margin-right: 13px;
}
.notify-dot {
  height: 10px;
  width: 10px;
  position: absolute;
  top: -2px;
  right: -1px;
  background: #fa4a25;
  border: 1px solid #fa4a25;
  border-radius: 100%;
}
.profile {
  display: flex;
  height: 100%;
  gap: 5px;
}
.profile-pic {
  margin-right: 5px;
  border-radius: 100%;
  background-color: #207ad3;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
}
.profile.detail {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 73px;
}
.profile-name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  text-align: start;
  line-height: 18px;
  color: #000000;
}
.profile-role {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  width: 100%;
  text-align: start;
  line-height: 14px;
  color: #a9a9a9;
}
.children {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  background-color: #f2f5f9;
}
.profile-dropdown {
  display: flex;
  position: relative;
  align-items: center;
}
.profile-dropdown-content {
  /* display: none; */
  /* position: absolute; */
  /* background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 6px 0px;
  z-index: 1;
  top: 35px;
  background-color: #ffffff;
  transform-origin: top right; */
  /* width: 11rem; */
  /* border-radius: 0.375rem; */
  /* box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --ring-color: #000000;
  --ring-opacity: 0.05;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}
.profile-dropdown-content button {
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  /* display: inline-flex;
  align-items: center;
  gap: 10px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  color: #374151;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border: none;
  width: 100%; */
}
/* .profile-dropdown-content button:hover {
  background-color: #f3f4f6;
}
.profile-dropdown:hover .profile-dropdown-content {
  display: block;
}
.profile-dropdown-content:hover .profile-dropdown-content {
  display: block;
} */
