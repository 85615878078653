.stock-header-actions {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 4px;
}
#compo .k-input {
  border-color: rgba(209, 213, 219, 1) !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  padding-left: 10px !important;
  font-weight: 400 !important;
  background-color: white !important;
  font-family: "Poppins" !important;
  color: black !important;
  &:focus,
  &:focus-within {
    border-width: 2px !important;
    /* border-color: black !important; */
    box-shadow: none !important;
  }
}
#compo .k-input-inner {
  padding: 0 !important;
  border: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  &:focus {
    border-width: 2px !important;
    /* border-color: black !important; */
    box-shadow: none !important;
  }
}
.stockMultiSelect .k-chip-list {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 0px;
  padding-right: 20px !important;
}
.stockMultiSelect .k-chip {
  width: fit-content;
  min-width: max-content;
  height: 20px;
}
.stockMultiSelect .k-chip .k-chip-content {
  width: fit-content;
  min-width: max-content;
}
.stockMultiSelect .k-chip .k-chip-label {
  width: fit-content;
  overflow-x: visible;
  text-overflow: unset;
  font-size: small;
  font-weight: 500;
}
.stockMultiSelect {
  height: 32px;
}
.stock-button {
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  padding: 0px 16px;
  align-items: center;
  height: 30px;
  border: 1px solid rgb(77 124 15);
  background-color: rgb(77 124 15);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  color: white;
  &:hover {
    background-color: rgb(54 83 20);
    border-color: rgb(54 83 20);
    outline: none;
  }
  &:disabled {
    background-color: slategray;
    color: slategrey;
    border-color: slategrey;
    box-shadow: none;
  }
}
.stock-export-button {
  display: flex;
  height: 30px;
  width: 96px;
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  border: 1px solid rgb(212 212 216);
  border-radius: 6px;
  &:hover {
    background-color: rgb(50, 24, 251);
    border-color: rgb(21, 159, 228);
    outline: none;
  }
  &:disabled {
    background-color: slategray;
    color: slategrey;
    border-color: slategrey;
    box-shadow: none;
  }
}
.location-input {
  display: flex;
  height: 27px;
  width: 200px;
  background-color: white;
  text-align: start;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 22px;
  gap: 4px;
  border: 1px solid rgb(212 212 216);
  border-radius: 6px;
  &:focus,
  &:focus-within {
    border-width: 2px;
    border-color: rgb(59, 130, 246);
    box-shadow: none;
    outline: none;
  }
}
